import React, { useState }  from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apps from './apps.json';
import './App.css';

function App() {
  // const recaptchaRef = React.useRef();
  const [mode, setMode] = useState('login')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')
  const [emailRecovery, setEmailRecovery] = useState('')
  const [isModalRecovery, setModalRecoveryValue] = useState(false)
  const query = new URLSearchParams(window.location.search);
  const app = query.get('app')
  const webhook = query.get('w')

  const redirectToApp = (token) => {
    if (app) {
      if (apps[app] && apps[app].redirect_url.length > 0) {
        window.location.href = apps[app].redirect_url + '?token=' + token;
      } else {
        toast.warning('Sorry, application ID is invalid')
      }
    } 

    if (webhook) {
      if (webhook.length > 20) {
        if (webhook.indexOf('?') > 0) {
          window.location.href = webhook + '&token=' + token;
        } else {
          window.location.href = webhook + '?token=' + token;
        }
      } else {
        toast.warning('Sorry, application ID is invalid')
      }
    }
  }

  const handleGoogleLogin = async (googleData) => {
    const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production" + "/api/v1/auth/login?app=" + app, {
        method: "POST",
        body: JSON.stringify(googleData),
        headers: {
          "Content-Type": "application/json"
        }
    })

    const data = await res.json();

    if (!data.success) {
      toast.warning(data.message)
    } else {
      redirectToApp(data.token)
    }
  }

  const handleLogin = async () => {
    if (email === '' || password === '') {
      toast.warning("Fields cannot be empty")
      return;
    } 

    if (email.search("@") === -1) {
      toast.warning("Invalid email")
      return;
    }

    if (email.length < 8) {
      toast.warning("Invalid email")
      return;
    }

    const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production" + "/api/v1/auth/login?app=" + app, {
      method: "POST",
      body: JSON.stringify({ user_email: email, user_password: password }),
      headers: {
        "Content-Type": "application/json"
      }
    })

    const data = await res.json();

    if (!data.success) {
      toast.warning(data.message)
    } else {
      redirectToApp(data.token)
    }
  }

  const handleGoogleRegister = async (googleData) => {
    const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production" + "/api/v1/auth/register?app=" + app, {
        method: "POST",
        body: JSON.stringify(googleData),
        headers: {
          "Content-Type": "application/json"
        }
    })

    const data = await res.json();

    if (!data.success) {
      toast.warning(data.message)
    } else {
      redirectToApp(data.token)
    }
  }

  const handleRegister = async () => {
    if (email === '' || password === '' || firstName === '' || lastName === '') {
      toast.warning('Fields cannot be empty')
      return;
    } 

    if (email.search("@") === -1) {
      toast.warning('Invalid email')
      return;
    }

    if (email.length < 8) {
      toast.warning('Invalid email')
      return;
    }

    const res = await fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production" + "/api/v1/auth/register?app=" + app, {
      method: "POST",
      body: JSON.stringify({ user_email: email, user_password: password, firstName, lastName, token }),
      headers: {
        "Content-Type": "application/json"
      }
    })

    const data = await res.json();

    if (!data.success) {
      toast.warning(data.message);
    } else {
      redirectToApp(data.token);
    }
  }

  const changeMode = (newMode) => {
    setMode(newMode)
  }

  const handleCaptchaChange = async (value) => {
    setToken(value)
  }
  
  const handleCloseModal = () => {
    setModalRecoveryValue(false)
    setEmailRecovery('')
  }

  const handlRecoveryPassword = () => {
    if (emailRecovery.length) {
      let data = {
        email: emailRecovery
      }
  
      fetch("https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production" + "/api/v1/auth/recovery?app=" + app, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
          .then(res => res.json())
          .then(res => {
              if (res.success && res.data) {
                toast.success('New password is sent to your email')
              } else {
                toast.error('Password is not sent')
              }
          })
          .catch((error) => {
              console.error('Error:', error);
          })
      
      handleCloseModal()
    }
  }

  const selectApp = (name) => {
    const exLink = window.location.href;
    window.location.href = exLink + '?app=' + name;
  }

  // if app is not selected
  // show app selection screen
  // else show app screen
  if (!app || !apps[app]) {
    return (
      <div className="App-error">
        <div className="App-error-header">
          <div className="App-error-title">Oh, it looks like you received the wrong link to WNC application...</div>
          <div className="App-error-subtitle">Choose one of our apps to continue login process:</div>
        </div>
        <div className="App-error-body">
          <div className="App-error-body-wrap">
            { Object.keys(apps).map((item, index) => {
              return (
                <div className="App-error-body-item" key={index} onClick={() => selectApp(item)}>
                  <img className="App-error-body-item-img" src={apps[item].logo_path} alt="App logo" />
                  <div className="App-error-body-item-title">{apps[item].title}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="App">

      { /* Recovery popup */
        isModalRecovery && 
          <div className="App-modal">
            <div className="App-modal-wrap">
              <div className="App-modal-background" onClick={handleCloseModal}></div>
              <div className="App-modal-text">
                <div className="App-modal-text-title">Enter your email</div>
                <input className="App-modal-input" size="sm" placeholder='Email' value={emailRecovery} onChange={(e) => setEmailRecovery(e.target.value)}/>
                <ReCAPTCHA
                  className="App-recaptcha"
                  style={{ maxWidth: '200px', height: '40px', width: '150px' }}
                  sitekey="6Lek0W4kAAAAAKL5_ME3lc1pgCsR-1-tNoGoghkr"
                  onChange={(value) => handleCaptchaChange(value)}
                  />
                <div className="App-modal-text-btn-wrap">
                  <button className="App-modal-text-btn App-modal-text-btn-cancel" onClick={handleCloseModal}>Cancel</button>
                  <button className="App-modal-text-btn App-modal-text-btn-send" onClick={handlRecoveryPassword}>Send</button>
                </div>
              </div>
            </div>
          </div>
      }

      {/* Banner (Left side) */}
      <div className='App-banner' style={{background: apps[app].banner_background, color: apps[app].banner_text_color}}>
        <img className='Banner-img' src={apps[app].banner_image_path} alt="App banner" />
        <div className='Banner-title'>{apps[app].banner_title}</div>
        <div className='Banner-subtitle'>{apps[app].banner_subtitle}</div>
      </div>

      {/* Login/register form (Right side) */}
      <GoogleOAuthProvider clientId="900085069920-bjlepnm91ffi5nrtn92hp63n81pif3el.apps.googleusercontent.com">
        <div className="App-form">

          {/* App logo */}
          <img src={process.env.PUBLIC_URL + apps[app].logo_path} className="App-logo" alt="logo" />
         
          {/* Form title */}
          <div className="App-title">
            { mode === 'register' ? apps[app].register_title : apps[app].login_title }
          </div>
          
          {/* Form subtitle */}
          <div className="App-subtitle">
            { mode === 'register' ? apps[app].register_subtitle : apps[app].login_subtitle }
          </div>

          {/* Google login/register button */}
          { mode === 'login' ?
            <GoogleLogin
              className='google-button'
              onSuccess={handleGoogleLogin}
              onError={handleGoogleLogin}
              useOneTap
            />
            :
            <GoogleLogin
              className='google-button'
              onSuccess={handleGoogleRegister}
              onError={handleGoogleRegister}
              useOneTap
            />
          }

          {/* Separator */}
          <div className='separator-line'>
            <div className='separator-line-text'>or Sign {mode === 'register' ? 'up' : 'in'} with Email</div>
          </div>

          {/* Additional inputs for 'register' mode */}
          { mode === 'register' ?
            <>
              <div className='label-box'>
                <label for="email">First Name</label>
                <input className="App-input" placeholder='' value={firstName} onChange={(e) => setFirstName(e.target.value)}/> 
              </div>
              <div className='label-box'>
                <label for="email">Last Name</label>
                <input className="App-input" placeholder='' value={lastName} onChange={(e) => setLastName(e.target.value)}/> 
              </div>
            </>
            :
            <></>
          }

          {/* Email input */}
          <div className='label-box'>
            <label for="email">Email</label>
            <input id="email" className="App-input" size="sm" placeholder='' value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>

          {/* Password input */}
          <div className='label-box'>
            <label for="password">Password</label>
            <input id="password" className="App-input" size="sm" placeholder='' value={password} onChange={(e) => setPassword(e.target.value)} type='password'/> 
            <div onClick={() => setModalRecoveryValue(true)} className='mode-link' style={{color: apps[app].main_color, marginLeft: 'auto'}}>Forgot your password?</div>
          </div>
          
          {/* Sign in/up buttons + recaptcha for 'register' mode */}
          { mode === 'login' ?
            <div>
              <button onClick={() => handleLogin()} style={{ marginBottom: '10px', backgroundColor: apps[app].main_color }}>Sign in</button>
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <button style={{ marginBottom: '10px', backgroundColor: apps[app].main_color }} onClick={() => handleRegister()}>Sign up</button>
              <ReCAPTCHA
                className="App-recaptcha"
                sitekey="6Lek0W4kAAAAAKL5_ME3lc1pgCsR-1-tNoGoghkr"
                onChange={(value) => handleCaptchaChange(value)}
                />
            </div>
          }

          {/* Link for changing the app mode from 'login' to 'register' */}
          { mode === 'login' ?
            <div className='mode-link-wrap'>
              <div className='mode-link-title'>Not Registered Yet?</div>
              <div className='mode-link' style={{color: apps[app].main_color}} onClick={() => changeMode('register')}>
                Create an account
              </div>
            </div>
            :
            <div className='mode-link-wrap'>
              <div className='mode-link-title'>Already have an account?</div>
              <div className='mode-link' style={{color: apps[app].main_color}} onClick={() => changeMode('login')}>
                Log in
              </div>
            </div>
          }
        </div>
      </GoogleOAuthProvider>

      {/* Notification container */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
